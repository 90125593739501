import {
  Activity,
  SearchFilters,
  User as UserType,
} from "@outschool/gql-backend-generated";

export type TopicInfo = {
  title: string;
  filters?: SearchFilters;
  basePath?: string;
  slug?: string;
};

export const isPublished = (activity: Pick<Activity, "published_at">) =>
  Boolean(activity.published_at);

export const isOwner = (
  activity: Pick<Activity, "user_uid">,
  user: Pick<UserType, "uid">
) => Boolean(user) && activity.user_uid === user.uid;

// Used on the activity detail page and in ads
export const MAIN_IMAGE_WIDTH = 344;
export const MAIN_IMAGE_WIDTH_1_9_ASPECT_RATIO = 344;
export const MAIN_IMAGE_HEIGHT = 179;
